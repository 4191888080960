
function makeStickyHeight() {
	var leftItemsHeight = $("#leftPane").height();
	var rightItemsHeight = $("#rightPane").height();

	var stickyWrapper = $(".sticky-wrapper-polyfill:not(.fixed-height)");

	if ( !leftItemsHeight || !rightItemsHeight || !stickyWrapper ) {
		return;
	}

	if (stickyWrapper.length > 1){
		stickyCount = stickyWrapper.length;
		var stickyWrapperHeight = stickyWrapper.height() * stickyCount;
	} else {
		var stickyWrapperHeight = stickyWrapper.height();
	}

	/*
		Calculating height for sticky on depending layout
	*/
	if (stickyWrapper.parent().attr('id') === "leftPane" ) {
		var leftPaneMargin = $('#leftPane').outerHeight(true) - $('#leftPane').outerHeight();
		var leftPaneElementsHeight = leftItemsHeight - stickyWrapperHeight;
		var resultDifference = rightItemsHeight - leftPaneElementsHeight - leftPaneMargin;
		resultDifference = resultDifference < 600 ? 600 : resultDifference;
		stickyWrapper.css('height', resultDifference + 'px');
	}
	else {
		var rightPaneMargin = $('#rightPane').outerHeight(true) - $('#rightPane').outerHeight();
		var rightPaneElementsHeight = rightItemsHeight - stickyWrapperHeight;
		if (stickyWrapper.length > 1){
			var resultDifference = (leftItemsHeight - rightPaneElementsHeight - rightPaneMargin) / stickyCount;
		}
		else {
			var resultDifference = leftItemsHeight - rightPaneElementsHeight - rightPaneMargin;
		}
		resultDifference = resultDifference < 600 ? 600 : resultDifference;
		stickyWrapper.css('height', resultDifference + 'px');
	}


	$(window).scroll();

}

$(function() {
	makeStickyHeight();
});
